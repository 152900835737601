import { useState } from 'react';
import moment from 'moment';
import { capitalise, time_ago } from '../entries/utils';
import LeadSourceBadge from '../leadCluster/LeadSourceBadge'
import { LeadClusterContext } from '../contexts'
import SlideIn from '../leadCluster/SlideIn'
import { useFetchDealership } from '../dataHooks'

const LeadClusterRow = ({ cluster }) => {

  let [leadCluster, setLeadCluster] = useState(cluster)

  return (
    <tr key={`lead-${leadCluster.id}`}>
      <LeadClusterContext.Provider value={{ leadCluster, setLeadCluster }}>
        <td>
          <SlideIn leadCluster={leadCluster} buttonComponent={({ setVisible }) => (
            <button
              className='btn btn-link'
              onClick={() => setVisible(true)}
            >
              {leadCluster.contact?.first_name}
            </button>
          )} />
        </td>
        <td>{capitalise(leadCluster.lead_state || '')}</td>
        <td>{leadCluster.user?.name}</td>
        <td><LeadSourceBadge leadSource={leadCluster.source} /></td>
        <td>
          <div>{time_ago(leadCluster.created_at)}</div>
          <div className="small text-secondary">{moment(leadCluster.created_at).format("DD/MM/YYYY")}</div>
        </td>
      </LeadClusterContext.Provider>
    </tr>
  )
}

const LeadClusterBox = ({ leadClusters }) => {
  useFetchDealership(window.dealership.slug)
  return (
    <div className="box mb-3">
      <div className="p-3">
        <h4 className='mb-1'><i className='fa fa-bolt mr-1'></i>Leads</h4>
      </div>
      <div className="table-responsive">
        <table className="table mb-0" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Assigned to</th>
              <th>Source</th>
              <th>Created at</th>
            </tr>
          </thead>
          <tbody>
            {leadClusters.map((leadCluster) => (
              <LeadClusterRow cluster={leadCluster} key={leadCluster.id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default LeadClusterBox