// MANAGED BY App.js
import { useState, useEffect, useContext } from 'react'
import {
  useNavigate,
  useParams
} from "react-router-dom"
import { InstantSearch, SearchBox, Configure, Pagination, useInstantSearch } from 'react-instantsearch'
import DateRangePicker from './DateRangePicker'
import CustomHits from '../leadCluster/CustomHits'
import CustomHitsBeta from './CustomHitsBeta'
import { humanize, meilisearchClient } from '../entries/utils'
import RefinementListSelect from '../editor/common/RefinementListSelect'
import Form from './Form'
import Loading from '../Loading'
import { DealershipContext, WebsiteContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'

const Col = ({ children }) => <div className="col-md-6 col-lg-4 col-xl-3 mb-2">{children}</div>

export const DownloadLink = () => {
  const { dealership } = useContext(DealershipContext)
  const { website } = useContext(WebsiteContext)
  const { dealershipSlug, websiteSlug } = useParams()

  const [downloadLink, setDownloadLink] = useState('')

  const { uiState, results } = useInstantSearch()

  let indexUiState = uiState[`Lead_${process.env.RAILS_ENV}:created_at:desc`]

  useEffect(() => {
    let defaultDownloadUrl = `/dealerships/${dealershipSlug}/leads/download_csv.csv`
    if (website?.id || websiteSlug) defaultDownloadUrl = `/websites/${website?.id || websiteSlug}/leads/download_csv.csv`

    let link = ''
    if (results.nbHits <= results.hits.length) {
      let data = []
      results.hits.forEach((hit) => {
        if (data.indexOf(hit.id) < 0)
          data.push(hit.id)
      })
      if (data.length > 0) {
        link = `${defaultDownloadUrl}?ids=${JSON.stringify(data)}`
      }
    } else {
      let refinements = indexUiState?.refinementList || {}
      if (indexUiState?.query) {
        refinements = { ...refinements, ...{ query: indexUiState?.query } }
      }
      if (indexUiState?.range && document.getElementById('flatpicker-date-range')) {
        const pickr = document.getElementById("flatpicker-date-range")._flatpickr
        let range = { "range": { min: pickr.selectedDates[0].getTime() / 1000, max: pickr.selectedDates[1].getTime() / 1000 + 60 * 60 * 24 + 30000 } }
        refinements = { ...refinements, ...range }
      }
      link = `${defaultDownloadUrl}?refinementList=${JSON.stringify(refinements)}`
    }
    setDownloadLink(link || defaultDownloadUrl)
  }, [website?.id, dealership?.slug])

  return (
    <a
      href={`${downloadLink}`}
      className="dropdown-item"
      id="download-csv"
    >
      Download CSV
    </a>
  )
}

export function LoadingIndicator() {
  const { status } = useInstantSearch()

  if (status === 'stalled') {
    return (
      <div className="py-3 mb-0 text-secondary">
        <Loading />
      </div>
    )
  }
  return null
}

const Wrapper = ({ defaultLayout = 'list' }) => {
  const { dealership } = useContext(DealershipContext)
  useFetchDealership()

  let [layout, setLayout] = useState(defaultLayout)
  let [searchParams, setSearchParams] = useState(false)
  const navigate = useNavigate()

  const changeLayout = (layout) => {
    setLayout(layout)
    if (!dealership?.slug) return
    if (layout === 'kanban') {
      navigate(`/dealerships/${dealership.slug}/leads/kanban`)
    } else {
      navigate(`/dealerships/${dealership.slug}/lead_clusters`)
    }
  }

  useEffect(() => {
    if (searchParams) {
      return
    }
    if (dealership?.slug) fetch('/dealerships/' + dealership.slug + '/leads.json').then(res => res.json()).then(data => {
      setSearchParams(data)
    })
  }, [dealership?.slug])

  return (
    <div className="pt-3 container-fluid">
      <div className="mb-2">
        <div className="btn-group btn-block">
          <button
            type="button"
            className={`btn ${layout === 'list' ? 'btn-secondary' : 'btn-outline-secondary'}`}
            onClick={() => changeLayout('list')}
          >
            <i className="fa fa-table mr-1" />
            Classic
          </button>
          <button
            type="button"
            className={`btn ${layout === 'kanban' ? 'btn-secondary' : 'btn-outline-secondary'}`}
            onClick={() => changeLayout('kanban')}
          >
            <i className="fa fa-bars mr-1" />
            Kanban Board
            <span className="badge badge-danger ml-2">BETA</span>
          </button>
        </div>
      </div>

      <div className={layout === 'list' ? 'd-block' : "d-none"}>
        {searchParams && searchParams.searchFilters && <SearchLeads {...searchParams} />}
      </div>

      {layout === 'kanban' && <CustomHitsBeta />}
    </div>
  )
}

const SearchLeads = ({ searchFilters, users, defaultLeadState, leadStatusOptions, ...searchParams }) => {
  const searchClient = meilisearchClient()

  let index = `Lead_${process.env.RAILS_ENV}:created_at:desc`

  let default_lead_state = defaultLeadState

  return (
    <InstantSearch
      indexName={index}
      searchClient={searchClient}
      routing={true}
      future={{
        preserveSharedStateOnUnmount: true
      }}
      initialUiState={{
        [`${index}`]: {
          refinementList: {
            lead_state: default_lead_state,
          },
        },
      }}
    >
      <Configure filters={searchFilters} hitsPerPage={40} />
      <div className="box p-3">
        <div className="row">
          <Col>
            <SearchBox className="mb-0" placeholder="Search Leads" />
          </Col>
          <Col>
            <RefinementListSelect
              attribute="lead_state"
              limit={20}
              transformItems={(items) =>
                items.map((item) => ({
                  ...item,
                  label: humanize(item.label),
                }))
              }
            />
          </Col>
          <Col>
            <RefinementListSelect attribute="category" limit={20} />
          </Col>
          <Col>
            <RefinementListSelect attribute="subcategory" />
          </Col>
          <Col>
            <RefinementListSelect attribute="source" />
          </Col>
          <Col>
            <RefinementListSelect attribute="website_name" limit={30} />
          </Col>
          <Col>
            <RefinementListSelect attribute="user_name" limit={30} />
          </Col>
          <Col>
            <div className="btn-group btn-block">
              <Form />
              <button
                id="btnGroupDrop1"
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                <DownloadLink />
              </div>
            </div>
          </Col>
          <Col>
            <RefinementListSelect
              attribute="location_name"
              limit={30}
            />
          </Col>
          <Col>
            <RefinementListSelect
              attribute="marketing_source"
            />
          </Col>
          <Col>
            <DateRangePicker attribute="created_at_timestamp" />
          </Col>
        </div>
      </div>
      <LoadingIndicator />
      <CustomHits leadStatusOptions={leadStatusOptions} users={users} recentlyUpdated={searchParams.recently_updated} />
      <div className="py-3">
        <Pagination />
      </div>
    </InstantSearch>
  )
}

export default Wrapper