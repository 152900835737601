// MANAGED BY App.js
import { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Select from 'react-select'
import { Link, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { Toast } from 'primereact/toast'

import EditForm from './EditForm'
import ToDoCars from './ToDoCars'
import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import Show from './Show'

const StockImageSets = () => {
  let [data, setData] = useState()
  let [loading, setLoading] = useState(false)
  let [selectedManufacturer, setSelectedManufacturer] = useState()
  let [selectedFamily, setSelectedFamily] = useState()
  let [selectedYear, setSelectedYear] = useState()
  const notification = useRef(null)

  const [searchParams, setSearchParams] = useSearchParams()

  const loadData = () => {
    setLoading(true)
    let url = '/stock_image_sets.json'
    let manufacturer_id_param = searchParams.get('manufacturer_id')
    if (selectedManufacturer || manufacturer_id_param) {
      const manufacturerId = selectedManufacturer || manufacturer_id_param
      url += `?manufacturer_id=${manufacturerId}`
    }

    if (selectedFamily) {
      url += `${url.includes('?') ? '&' : '?'}family_id=${selectedFamily.value}`
    }

    if (selectedYear) {
      url += `${url.includes('?') ? '&' : '?'}year=${selectedYear.value}`
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setData(data)
        if (!selectedManufacturer) {
          setSelectedManufacturer(data.selected_manufacturer?.id || data.manufacturers[0].id)
        }
        setLoading(false)
      })
  }

  useEffect(() => {
    loadData()
  }, [selectedYear, selectedFamily, selectedManufacturer])

  const changeMake = (selectedOption) => {
    setSelectedManufacturer(selectedOption.value)
  }

  const changeFamily = (selectedOption) => {
    setSelectedFamily(selectedOption)
  }

  const changeYear = (selectedOption) => {
    setSelectedYear(selectedOption)
  }

  const Photo = (rowData) => {
    return (
      <>
        {rowData.primary_photo_url && (
          <img
            src={rowData.primary_photo_url}
            style={{ width: '100px' }}
            className="rounded border"
          />
        )}
      </>
    )
  }

  const Actions = (rowData) => {
    const handleDelete = () => {
      if (confirm('Are you sure you want to delete this stock image set?')) {
        fetch(`/stock_image_sets/${rowData.id}`, {
          method: 'DELETE',
          headers: standardHeaders,
        }).then((response) => {
          if (response.ok) {
            showToast(notification, 'success', 'Stock Image Set deleted successfully')
            loadData()
          }
        })
      }
    }

    return (
      <div>
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-sm btn-outline-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fa fa-edit mr-2"></i>
            Edit
          </button>
          <div className="dropdown-menu">
            <Show stockImageSet={rowData} setStockImageSets={setData} stockImageSets={data} />
            <EditForm stockImageSet={rowData} />
            <a href={`/stock_image_sets/${rowData.id}/photos/new`} className="dropdown-item">
              <i className="fa fa-plus mr-2"></i>
              Photos
            </a>
            <a href={`/stock_image_sets/${rowData.id}/duplicate`} className="dropdown-item">
              <i className="fa fa-copy mr-2"></i>
              Duplicate
            </a>
            <button onClick={handleDelete} className="dropdown-item text-danger">
              <i className="fa fa-trash mr-2"></i>
              Delete
            </button>
          </div>
        </div>
      </div>
    )
  }

  const Family = (rowData) => {
    return (
      <div>
        {rowData.family.name} {rowData.body} {rowData.badge}
        <div className="small text-secondary">{rowData.bodyconfiguration}</div>
      </div>
    )
  }

  const Created = (rowData) => {
    return <div>{moment(rowData.created_at).fromNow()}</div>
  }

  return (
    <div className="container py-3">
      <ToDoCars />
      <Toast ref={notification} />
      <div className="box">
        <div className="p-3">
          <h5 className="mb-3">Stock Image Sets</h5>
          <div className="row">
            {selectedManufacturer && (
              <>
                <div className="col-md-4 mb-2">
                  <Select
                    placeholder="Select Make"
                    isSearchable={true}
                    defaultValue={{
                      value: selectedManufacturer,
                      label: data?.manufacturers?.find((manufacturer) => manufacturer.id === selectedManufacturer)?.name,
                    }}
                    onChange={changeMake}
                    options={data?.manufacturers?.map((manufacturer) => {
                      return { value: manufacturer.id, label: manufacturer.name }
                    })}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <Select
                    placeholder="Select Model"
                    isClearable={true}
                    isSearchable={true}
                    onChange={changeFamily}
                    value={selectedFamily}
                    options={data?.families?.map((family) => {
                      return { value: family.id, label: family.name }
                    })}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <Select
                    placeholder="Select Year"
                    isClearable={true}
                    isSearchable={true}
                    onChange={changeYear}
                    value={selectedYear}
                    options={data?.years?.map((year) => {
                      return { value: year, label: year }
                    })}
                  />
                </div>
              </>
            )}
            <div className="col-md-4">
              <Link to="/stock_image_sets/new" className="btn btn-outline-primary btn-block">
                <i className="fa fa-plus mr-2"></i>
                Stock Image Set
              </Link>
            </div>
          </div>
        </div>
        {loading && (
          <div className="text-center p-3">
            <i className="fa fa-spinner fa-spin mr-2"></i>
            Loading Stock Image Sets...
          </div>
        )}
        {data?.stock_image_sets && (
          <DataTable value={data.stock_image_sets} loading={loading}>
            <Column sortable body={Photo} header="Photo"></Column>
            <Column sortable field="year" header="Year"></Column>
            <Column sortable field="manufacturer.name" header="Make"></Column>
            <Column sortable body={Family} field="family.name" header="Model"></Column>
            <Column sortable field="colour" header="Colour"></Column>
            <Column sortable field="photos_count" header="Photo count"></Column>
            <Column body={Created} field="created_at" header="Created" />
            <Column header="Actions" body={Actions} />
          </DataTable>
        )}
      </div>
    </div>
  )
}

export default StockImageSets
