// MANAGED BY App.js
import { useState, useEffect } from 'react'
import { humanize } from '../entries/utils'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Switch } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'
import { Tooltip } from 'react-tooltip'
import { useParams } from 'react-router-dom'
import PipelineForm from './PipelinesForm'

export const Toggle = ({ attributeName, objectType, rowData, url }) => {

  let [attribute, setAttribute] = useState(rowData?.[attributeName])
  let [loading, setLoading] = useState(false)

  let onChange = () => {
    let newValue = !attribute
    setAttribute(newValue)
    setLoading(true)
    let data = {
      [objectType]: {
        [attributeName]: newValue
      }
    }

    fetch(url, {
      method: 'put',
      body: JSON.stringify(data),
      headers: standardHeaders,
    }).then(res => res.json()).then(res => {
      setAttribute(res[attributeName])
      setLoading(false)
    })
  }

  return (
    <Switch
      value={attribute}
      onChange={onChange}
      isDisabled={loading || rowData.locked}
      id={`${attributeName}-switch-${rowData.id}`}
    />
  )
}

const DeletePipelineButton = ({ pipeline, loadPipelines }) => {
  let [loading, setLoading] = useState(false)

  const DeletePipeline = () => {
    setLoading(true)
    fetch(`/dealerships/${window.dealership.id}/pipelines/${pipeline.id}`, {
      method: 'DELETE',
      headers: standardHeaders,
    }).then(res => res.json()).then(res => {
      setLoading(false)
      loadPipelines()
    })
  }

  // Don't allow deletion of the Sales pipeline (default pipeline)
  if (pipeline.name === 'Sales') {
    return null
  }

  return (
    <div
      className="btn btn-outline-danger btn-sm mr-2"
      onClick={DeletePipeline}
    >
      {loading ? <i className="fa fa-spin fa-spinner" /> : <i className="fa fa-trash" />} Delete Pipeline
    </div>
  )
}

const EditPipelineButton = ({ pipeline, loadPipelines, categories }) => {

  return (
    <PipelineForm
      pipeline={pipeline}
      loadPipelines={loadPipelines}
      categories={categories}
      action="edit"
      buttonComponent={({ setVisible }) => (
        <button
          className="btn btn-outline-primary btn-sm mr-2"
          onClick={() => setVisible(true)}
          id="edit-pipeline-button"
        >
          Edit Pipeline
        </button>
      )}
    />
  )
}

const Pipeline = ({ pipeline, loadPipelines, categories }) => {
  let [leadStatusOptions, setLeadStatusOptions] = useState(pipeline.lead_status_options)
  let [loading, setLoading] = useState(false)

  const EditTemplate = (rowData) => (
    <a
      className={"btn btn-outline-primary btn-sm" + (rowData.locked ? ' disabled' : '')}
      href={`/dealerships/${window.dealership.id}/lead_status_options/${rowData.id}/edit`}
    >
      Edit
    </a>
  )

  const Colour = (rowData) => (
    <div className={`badge badge-${rowData.colour_class_name}`}>
      {humanize(rowData.colour_name)}
    </div>
  )

  const StatusType = (rowData) => (
    <div>
      {humanize(rowData.status_type)}
    </div>
  )

  const Active = (rowData) => (
    <Toggle objectType="lead_status_option" attributeName="active" rowData={rowData} url={`/dealerships/${window.dealership.id}/lead_status_options/${rowData.id}`} />
  )

  const RequiresApproval = (rowData) => (
    <Toggle objectType="lead_status_option" attributeName="requires_approval" rowData={rowData} url={`/dealerships/${window.dealership.id}/lead_status_options/${rowData.id}`} />
  )

  const onReorder = (e) => {

    let data = {
      ids: e.value.map(v => v.id),
      pipeline_id: pipeline.id
    }

    setLoading(true)
    fetch(`/dealerships/${window.dealership.id}/lead_status_options/sort`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: standardHeaders,
    }).then(res => res.json()).then(res => {
      setLeadStatusOptions(res)
      setLoading(false)
    })
  }

  const Name = (rowData) => (
    <div className={rowData.active ? '' : "text-secondary"}>
      {humanize(rowData.name)}
      {rowData.locked ? <i className="fa fa-lock ml-2" /> : ''}
    </div>
  )

  const RequiresApprovalHeading = () => {
    return (
      <div>
        Requires Approval
        <i className="fa fa-question-circle ml-2" id="hidden-tooltip" />
        <Tooltip
          anchorId={`hidden-tooltip`}
          place="bottom"
          style={{ zIndex: 10000 }}
        >
          If turned on, requires manager approval
        </Tooltip>
      </div>
    )
  }

  return (
    <div className="box mb-3">
      <div className="p-3">
        <div className="d-flex align-items-center">
          <div>
            <h5 className="m-0">
              Pipeline: {pipeline.name}
            </h5>
          </div>
          <div className='ml-auto'>
            <DeletePipelineButton pipeline={pipeline} loadPipelines={loadPipelines} />
            <EditPipelineButton pipeline={pipeline} loadPipelines={loadPipelines} categories={categories} />
            <a
              className="btn btn-outline-primary btn-sm"
              href={`/dealerships/${window.dealership.slug}/lead_status_options/new?pipeline_id=${pipeline.id}`}
            >
              <i className="fa fa-plus" /> Lead Status Option
            </a>
          </div>
        </div>
      </div>
      <hr className="my-1" />
      <div className="px-3 pt-2 pb-3">
        <div>
          <div className="text-secondary">
            Applies to:
          </div>
          <div>
            {pipeline.categories.map(c => <div className="badge badge-secondary badge-sm mr-2">{c}</div>)}
          </div>
        </div>
      </div>
      <DataTable
        value={leadStatusOptions.sort((a, b) => a.position - b.position)}
        reorderableColumns
        reorderableRows
        onRowReorder={onReorder}
        loading={loading}
      >
        <Column rowReorder style={{ width: '3rem' }} />
        <Column field="name" header="Name" body={Name} />
        <Column body={StatusType} field="status_type" header="Stage" />
        <Column body={Colour} field="colour_name" header="Colour" />
        {/* <Column body={Hidden} field="hidden" header="Hidden" /> */}
        <Column body={Active} header="Active" />
        <Column body={RequiresApproval} header={RequiresApprovalHeading} />
        <Column body={EditTemplate} header="Edit" />
      </DataTable>
    </div>
  )
}

const LeadSettings = () => {

  let { dealershipSlug } = useParams()
  let [pipelines, setPipelines] = useState([])
  let [loading, setLoading] = useState(false)
  let [data, setData] = useState(false)

  const loadPipelines = () => {
    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/pipelines.json`)
      .then(response => response.json())
      .then(data => {
        setPipelines(data.pipelines)
        setData(data)
        setLoading(false)
      })
  }

  useEffect(() => {
    loadPipelines()
  }, [])


  return (
    <>
      <div className="box mb-2">
        <div className="p-3">
          <h4>Lead Settings: Pipelines & Lead Status Options</h4>
          <div className="text-secondary">
            <p>
              In Dealer Studio, "Pipelines" are collections of leads (such as service, sales, parts, or buying leads), each with its own set of "lead status options."
              Leads progress through their designated pipeline by transitioning between these status options.
            </p>
            <p>
              By default, we create only a <i>"Sales"</i> pipeline for you. You can optionally create additional pipelines however, in many cases this is not necessary.
            </p>
          </div>
        </div>
      </div>
      {loading && <div className="text-center"><i className="fa fa-spin fa-spinner" /></div>}
      {pipelines.map(pipeline => <Pipeline
        pipeline={pipeline}
        key={`pipeline-${pipeline.id}`}
        loadPipelines={loadPipelines}
        categories={data?.available_categories_for_dealership}
      />)}
      {data && (
        <PipelineForm
          categories={data?.available_categories_for_dealership}
          loadPipelines={loadPipelines}
          buttonComponent={({ setVisible }) => (
            <button
              className="btn btn-outline-primary"
              onClick={() => setVisible(true)}
              id="new-pipeline-button"
            >
              New Pipeline
            </button>
          )}
        />
      )}
    </>
  )
}

export default LeadSettings