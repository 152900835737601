import { useEffect, useState, useContext } from 'react'
import { formatDistanceToNow } from 'date-fns'
import OwnerSelect from './OwnerSelect'
import StateSelect from './StateSelect'
import { updateLeadUserId, updateLeadState } from './utils'
import EditableItem from './EditableItem'
import LeadSourceBadge from '../leadCluster/LeadSourceBadge'
import { Tooltip } from 'react-tooltip'
import { standardHeaders } from '../entries/utils'

import LeadTestDrive from './LeadTestDriveLabel'
import TestDriveButton from '../testDrives/components/TestDriveButton'
import showToast from '../shared/ShowToast'
import { LeadContext } from '../contexts'
const timeAgo = (date) => {
  if (!date) return 'invalid date'

  return formatDistanceToNow(new Date(date), { addSuffix: true })
}

const UserAssignment = () => {
  const { lead } = useContext(LeadContext) || {}
  const [assignedUserId, setAssignedUserId] = useState(lead.user_id)

  const onOwnerChange = (leadId, userId) => {
    setAssignedUserId(userId)
    updateLeadUserId(leadId, userId)
  }

  return (
    <div className="px-3">
      <div className="small mb-1"><b>Assigned to:</b></div>
      <OwnerSelect
        hit={lead}
        onOwnerChange={onOwnerChange}
        selectedValue={assignedUserId}
      />
    </div>
  )
}

const ResendButton = () => {
  const { lead } = useContext(LeadContext) || {}
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const resendToThirdParties = () => {
    setLoading(true)
    fetch(`/dealerships/${lead.dealership_id}/leads/${lead.id}/resend_to_third_parties`, {
      method: 'POST',
      headers: standardHeaders
    }).then(res => res.json())
      .then(() => {
        setLoading(false)
        setSent(true)
      })
  }

  return (
    <div
      onClick={resendToThirdParties}
      className={"btn btn-outline-secondary btn-block mb-3" + (sent ? " disabled" : "")}
      disabled={sent || loading}
    >
      {sent ? (
        <div className="text-success">
          <i className="fa fa-check mr-2"></i>
          Resent to third party
        </div>
      ) : (
        <>
          <i className="fa fa-recycle mr-2"></i>
          {loading ? "Loading..." : "Resend to third parties"}
        </>
      )}
    </div>
  )
}

const RequestPayment = ({ notification }) => {
  const { lead } = useContext(LeadContext) || {}

  const [loading, setLoading] = useState(false)
  const [paymentRequests, setPaymentRequests] = useState(lead.paymentRequests)
  let csrfToken = document.querySelector('meta[name="csrf-token"]')?.content
  let payment_button = document.getElementById(`payment-${lead.id}`)
  const paymentRequestsForLead = () => {
    setLoading(true)
    fetch(`/dealerships/${lead.dealership_id}/leads/${lead.id}/request_payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
        'accept': 'application/json'
      }
    }).then(res => res.json())
      .then(res => {
        setPaymentRequests(res.payment_requests)
        document.getElementById(`payment-${lead.id}`).setAttribute('disabled', getPendingRequests().length > 0)
        showToast(notification, 'success', 'Payment Request Sent!', 'Payment Request for this Lead has been sent successfully.')
      })
      .then(() => setLoading(false))
  }

  const getPendingRequests = () => {
    return paymentRequests.filter(rp => rp.status === 'payment_requested')
  }
  return (
    <button
      id={`payment-${lead.id}`}
      onClick={paymentRequestsForLead}
      disabled={getPendingRequests().length > 0}
      className="btn btn-outline-secondary btn-block mb-3"
    >
      <i className="fa fa-envelope mr-2" ></i>
      {loading ? "Loading..." : "Request Payment"}
      <Tooltip
        anchorSelect={`#payment-${lead.id}`}
        place="left"
        style={{ zIndex: 10000 }}
      >
        {payment_button?.disabled ? `Payment Request Sent ${new Date(getPendingRequests()[0].created_at)
          .toLocaleString('en-AU', {
            timeZone: 'Australia/Brisbane',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
          }` : "Click to Request Payment Link for the vehicle attached to this lead."}
      </Tooltip>
    </button>

  )
}

function LeadDetails({ status, notification }) {
  const { lead, features } = useContext(LeadContext)

  let [contact, setContact] = useState(lead?.contact)

  useEffect(() => {
    setContact(lead?.contact)
  }, [lead])

  const onStateChange = (leadId, newState) => {
    return updateLeadState(leadId, newState)
  }

  return (
    <div className="col-lg-3">
      <div className="bg-white border-left h-100">
        <div className="pt-3 px-3">
          <div className="mr-auto small mb-1"><b>Lead Status:</b></div>
          {lead && lead?.lead_state && <StateSelect
            hit={lead}
            attribute="lead_state"
            onStateChange={onStateChange}
            selectedValue={lead.lead_state}
            leadStatusOptions={lead.lead_status_options}
          />}
        </div>
        <hr />
        {lead && status === 'completed' && (
          <UserAssignment />
        )}
        <hr />
        <div className="pt-1 px-3">
          <div className="d-flex align-items-center">
            <div className="mr-auto"><b>Created:</b></div>
            {lead && <div className="text-secondary text-right">
              {timeAgo(lead.created_at)}
              <div className="small"> ({
                new Date(lead.created_at)
                  .toLocaleString('en-AU', {
                    timeZone: 'Australia/Brisbane',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })
              })</div>
            </div>}
          </div>
        </div>
        <hr />
        <div className="pt-1 px-3">
          <div className="d-flex align-items-center">
            <div className="mr-auto"><b>Category:</b></div>
            <div className="text-secondary">{lead?.category}</div>
          </div>
          <div className="d-flex align-items-center">
            <div className="mr-auto"><b>Subcategory:</b></div>
            <div className="text-secondary text-right">{lead?.subcategory}</div>
          </div>
        </div>
        {lead?.test_drive_status && features.test_drives && (
          <>
            <hr />
            <div className="pt-1 px-3">
              <div className="d-flex align-items-center">
                <div className="mr-auto"><b>Test Drive:</b></div>
                <LeadTestDrive features={features} />
              </div>
            </div>
          </>
        )}
        <hr />
        <div className="pt-1 px-3">
          <div className="d-flex align-items-center">
            <div className="mr-auto"><b>Location:</b></div>
            <div className="text-secondary text-right">{lead?.location?.name}</div>
          </div>
        </div>
        {lead?.website_name && (
          <>
            <hr />
            <div className="pt-1 px-3">
              <div className="d-flex align-items-center">
                <div className="mr-auto"><b>Website:</b></div>
                <div className="text-secondary text-right">{lead?.website?.name}</div>
              </div>
            </div>
          </>
        )}
        {lead?.provider && (
          <>
            <hr />
            <div className="pt-1 px-3">
              <div className="d-flex align-items-center">
                <div className="mr-auto"><b>Provider:</b></div>
                <div className="text-secondary text-right">{lead?.provider}</div>
              </div>
            </div>
          </>
        )}
        {lead?.source && (
          <>
            <hr />
            <div className="pt-1 px-3">
              <div className="d-flex align-items-center">
                <div className="mr-auto"><b>Source:</b></div>
                <LeadSourceBadge />
              </div>
            </div>
          </>
        )}
        <hr />
        <div>
          <EditableItem field="first_name" label="First Name" value={contact?.first_name} setContact={setContact} />
          <EditableItem field="last_name" label="Last Name" value={contact?.last_name} setContact={setContact} />
          <EditableItem field="phone_number" label="Phone" value={contact?.phone_number} setContact={setContact} />
          <EditableItem field="email" label="Email" value={contact?.email} setContact={setContact} />
          <EditableItem
            lead={lead}
            field="date_of_birth"
            placeholder='dd/mm/yyyy'
            label="DOB"
            value={contact?.date_of_birth}
            setContact={setContact}
          />
        </div>
        <hr />
        {lead && (
          <div className="px-3 pt-1">
            <a href={`/dealerships/${lead.dealership_id}/contacts/${lead.contact_id}`} className="btn btn-outline-secondary btn-block">
              <i className="fa fa-user mr-2"></i>
              View Contact
            </a>
            {features.test_drives && lead?.test_drive_status && !lead.car?.test_drive_in_progress_lead_id && (
              <TestDriveButton />
            )}
            <a href={`/dealerships/${lead.dealership_id}/leads/${lead.id}/edit`} className="btn btn-outline-secondary btn-block">
              <i className="fa fa-edit mr-2"></i>
              Edit
            </a>
            <ResendButton />
            {lead?.paymentRequestEligible && (lead?.car) && (
              <RequestPayment notification={notification} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default LeadDetails