// MANAGED BY App.js
import { useState, useEffect, useRef, useContext, useMemo } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Toast } from 'primereact/toast'

import LeadEventsContainer from './LeadEvents'
import Appointments from '../appointments/Appointments'
import PhoneCalls from '../phoneCalls/phoneCalls'
import LeadPayment from '../leads/LeadPayment'
import SmsConversation from '../leads/SmsConversation'
import LeadClusterSidebar from './Sidebar'
import { Skeleton } from 'primereact/skeleton'
import { DealershipContext, LeadClusterContext } from '../contexts'
import Cars from './Cars'
import LeadContainer from './LeadContainer'
import { Message } from 'primereact/message'
import ProgressTracker from './ProgressTracker'
import LeadClusterStateChanges from './LeadClusterStateChanges'
import { useFetchDealership } from '../dataHooks'
import { Panel } from 'primereact/panel'
import { csrfToken } from '../entries/utils'
import showToast from '../shared/ShowToast'
import * as Routes from '../../routes'

const TabOne = () => {
  return (
    <>
      {/* {lead?.creditScoreRequests.map((creditScoreRequest) => <CreditScoreRequest creditScoreRequest={creditScoreRequest} key={creditScoreRequest.id} />)} */}
      <Appointments />
      <PhoneCalls />
      <LeadEventsContainer />
    </>
  )
}

export const LeadMain = ({ sidebar = true }) => {
  const navigate = useNavigate()

  const handleBack = () => {
    // Go back to the previous page
    navigate(-1)
  }
  const { leadCluster, notification } = useContext(LeadClusterContext)

  const [leadClusterFiles, setLeadClusterFiles] = useState(leadCluster.files)

  const firstLead = leadCluster.leads[0]

  const [selectedFile, setSelectedFile] = useState(undefined)

  const fileInputRef = useRef(null)

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const handleFileUpload = () => {
    if (!selectedFile) return

    const formData = new FormData()
    formData.append('files', selectedFile)

    async function uploadFile(leadCluster, formData) {
      try {
        const response = await fetch(Routes.file_upload_dealership_lead_cluster_path(leadCluster.dealership_id, leadCluster.id), {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrfToken,
          },
          body: formData
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        return data
      } catch (error) {
        throw error
      }
    }

    uploadFile(leadCluster, formData)
      .then(data => {
        showToast(notification, 'success', 'Success', 'File uploaded successfully.')
        setLeadClusterFiles(data.files)
        setSelectedFile(undefined)
        fileInputRef.current.value = ''
      })
      .catch(error => {
        showToast(notification, 'success', 'Error', 'File upload failed.')
      })
  }

  const handleFileDelete = async (fileId) => {
    async function deleteFile(leadCluster, fileId) {
      try {
        const response = await fetch(Routes.file_delete_dealership_lead_cluster_path(leadCluster.dealership_id, leadCluster.id), {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': csrfToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ file_id: fileId })
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        return data
      } catch (error) {
        throw error
      }
    }

    deleteFile(leadCluster, fileId)
      .then(data => {
        showToast(notification, 'success', 'Success', 'File deleted successfully.')
        setLeadClusterFiles(data.files)
      })
      .catch(error => {
        showToast(notification, 'error', 'Error', 'File delete failed.')
      }
      )
  }

  return (
    <>
      {sidebar ? (
        <div className="px-3 pt-1">
          <button className="btn btn-outline-secondary btn-block-md-down mr-auto mb-2" onClick={handleBack}>
            <i className="fas fa-arrow-left mr-2" /> Back
          </button>
        </div>
      ) : (
        <div className="px-3 pt-1">
          <a
            className="btn btn-outline-primary btn-block mb-2"
            href={`/dealerships/${window.dealer_slug}/lead_clusters/${leadCluster.id}`} target="_blank"
          >
            <i className="fa fa-external-link mr-1"></i>
            Open Lead
          </a>
        </div>
      )}
      {leadCluster.requires_approval && (
        <div className="mb-2 px-3 w-100">
          <Message className="w-100" severity="warn" text={`Lead requires manager approval to move to status "${leadCluster.lead_state}"`} />
        </div>
      )}
      <ProgressTracker />
      <Tabs>
        <div className="px-3 py-1">
          <div className="rounded border overflow-hidden lead-tabs">
            <TabList>
              <div className="d-flex">
                <Tab>Lead Events</Tab>
                <Tab>
                  <div id="sms-conversation-button">
                    SMS Conversation ({leadCluster.texts?.length})
                  </div>
                </Tab>
              </div>
            </TabList>
          </div>
        </div>
        <TabPanel>
          <div className="px-3">
            {leadCluster.payments.map(payment => (
              <LeadPayment payment={payment} key={payment.id} />
            ))}
            <Cars />
            {leadCluster.state_changes.length > 0 && <LeadClusterStateChanges stateChanges={leadCluster.state_changes} />}
            {firstLead?.visited_pages?.length > 0 && (
              <p className="small mb-1 mt-2 text-secondary text-center">
                {leadCluster?.contact.first_name} visited {firstLead.visited_pages.length} pages before submitting first lead
              </p>
            )}
          </div>
          <div className="px-3 py-2">
            <Panel header={`Leads (${leadCluster.leads.length})`} toggleable>
              {leadCluster.leads.map((lead, i) => (
                <LeadContainer lead={lead} key={lead.id} position={i} />
              ))}
            </Panel>
          </div>
          <div className="px-3 py-2">
            <Panel header={`File Uploads (${leadClusterFiles.length})`} toggleable>
              {leadClusterFiles.map(file => (
                <div key={file.id} className="d-flex justify-content-between align-items-center mb-2">
                  <a href={file.url} className="text-primary">
                    {file.filename}
                  </a>
                  <button className="btn btn-danger btn-sm" onClick={() => handleFileDelete(file.id)}>
                    Delete
                  </button>
                </div>
              ))}

              <div className="mt-2">
                <input type="file" ref={fileInputRef} onChange={handleFileChange} />
                <button className="btn btn-primary" disabled={selectedFile === undefined ? true : false} onClick={handleFileUpload}>
                  Upload File
                </button>
              </div>
            </Panel>
          </div>
          <TabOne></TabOne>
        </TabPanel>
        <TabPanel>
          <SmsConversation />
        </TabPanel>
      </Tabs>
    </>
  )
}

const Show = ({ deafaultLeadClusterId, sidebar = true }) => {
  let { leadClusterId } = useParams()
  let clusterId = leadClusterId || deafaultLeadClusterId

  const { state } = useLocation()
  const [status, setStatus] = useState('loading')
  const rootElement = document.getElementById('app_root')
  const testDrivesEnabled = rootElement?.getAttribute('test_drives_enabled') === 'true'
  const features = { 'test_drives': testDrivesEnabled }
  const notification = useRef(null)

  let leadCluster, setLeadCluster

  if (sidebar) {
    [leadCluster, setLeadCluster] = useState(state)
  } else {
    ({ leadCluster, setLeadCluster } = useContext(LeadClusterContext))
  }

  const { dealership } = useContext(DealershipContext)
  useFetchDealership()

  useEffect(() => {
    if (status !== 'completed' && dealership?.slug) {
      fetch(`${window.location.origin}/dealerships/${dealership.slug}/lead_clusters/${clusterId}.json`)
        .then((response) => {
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error('Not Found')
            } else {
              throw new Error('An error occurred')
            }
          }
          return response.json()
        })
        .then((data) => {
          setLeadCluster(data)
          setStatus('completed')
        })
        .catch((error) => {
          if (error.message === 'Not Found') {
            setStatus('not_found')
          } else {
            setStatus('error')
          }
        })
    }
  }, [dealership?.slug])

  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [])

  const contextValue = useMemo(() => ({ leadCluster, features, setLeadCluster, notification }), [leadCluster, features, setLeadCluster, notification])

  if (status === 'not_found') {
    return (
      <div className="text-center mt-5 text-danger">
        <h2>Lead Cluster Not Found</h2>
      </div>
    )
  }

  const Content = (
    <div className="w-100">
      <div className="row no-gutters h-100">
        <div className={sidebar ? "col-md-9" : 'col-md-12'}>
          <div className="py-3">
            {status === 'completed' ? <LeadMain sidebar={sidebar} /> : (
              <div className="px-3 pt-1 py-3">
                <Skeleton
                  width="10rem"
                  className="mb-2 w-100"
                  height="10rem"
                />
                <Skeleton
                  width="10rem"
                  className="mb-2 w-100"
                  height="10rem"
                />
                <Skeleton
                  width="10rem"
                  className="mb-2 w-100"
                  height="10rem"
                />
              </div>
            )}
          </div >
        </div>
        <Toast ref={notification} />
        {sidebar && (
          <LeadClusterSidebar
            status={status}
            notification={notification}
          />
        )}
      </div>
    </div>
  )

  // Wrap in LeadClusterContext.Provider if sidebar is false
  // Otherwise we end up in a situation where we have LeadClusterContext.Provider twice (nested)
  // And when we call setLeadCluster from the grandchild it does not update the grandfather
  return sidebar ? (
    <LeadClusterContext.Provider value={contextValue}>
      {Content}
    </LeadClusterContext.Provider>
  ) : (
    Content
  )
}

export default Show