import { useState, useContext, useEffect } from 'react'
import SortPhotos from './SortPhotos'
import Lightbox from "yet-another-react-lightbox"
import { CarContext } from '../contexts'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const Wrapper = ({ dealership }) => {

  let { car } = useContext(CarContext)

  return (
    <div className="box mb-3 position-relative lead-event-form">
      <Tabs>
        <div className="p-2 border-bottom">
          <TabList>
            <Tab>
              <i className="fa fa-image mr-2"></i>
              Photos
            </Tab>
            <Tab>
              <i className="fa fa-rotate mr-2"></i>
              360 Spin
            </Tab>
            <Tab>
              <i className="fa fa-trash mr-2"></i>
              Trash
            </Tab>
          </TabList>
        </div>
        <TabPanel>
          <CarPhotos dealership={dealership} />
        </TabPanel>
        <TabPanel>
          <div className="px-3 py-3">
            <h5>
              360 spin
            </h5>
            {car.spin_code ? (
              <iframe
                height="600"
                width="100%"
                src={`https://static.instavid360.com/v/latest/player.html?version=0.7&spin=${car.spin_code}`}
              />
            ) : (
              <p>No spin has been attached to this car.</p>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <DeletedPhotos />
        </TabPanel>
      </Tabs>
    </div>
  )
}

const DeletedPhotos = () => {
  let { car } = useContext(CarContext)
  let [loading, setLoading] = useState(false)
  let [deletedPhotos, setDeletedPhotos] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetch(`/cars/${car.id}/deleted_photos.json`)
      .then(res => res.json())
      .then(res => {
        setDeletedPhotos(res)
        setLoading(false)
      })
  }, [])

  return (
    <div className="p-3">
      <p>Recently deleted photos:</p>
      {loading ? "Loading..." : (
        <div className="row">
          {deletedPhotos && deletedPhotos.map(photo => (
            <div className="col-6 col-md-2 mb-2">
              <img
                src={photo}
                height="100"
                className='img-fluid car-thumb-photo rounded'
                style={{ minHeight: 100 }}
              />
            </div>
          ))}
        </div>
      )}
      <p className="text-secondary small">
        Please note, photos are only kept for 3 days after they are deleted.
      </p>
    </div>
  )
}

const CarPhotos = ({ dealership }) => {
  let { car } = useContext(CarContext)

  const photos = car.images || []

  let [index, setIndex] = useState(0)
  let [loading, setLoading] = useState(false)
  let [open, setOpen] = useState(false)
  let [updatedPhotos, setUpdatedPhotos] = useState([])

  const sendPhotosToServer = () => {
    if (updatedPhotos !== photos) {
      setLoading(true)
      fetch(Routes.sort_car_photos_path(car.slug), {
        method: 'PATCH',
        body: JSON.stringify({
          photo: updatedPhotos.map(item => item.id),
          photos_changed: true
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then(res => res.json())
        .then(() => {
          setLoading(false)
          window.location.reload()
        })
    }
  }

  return (
    <div className="p-3">
      <h4>Photos</h4>
      <SortPhotos
        photos={photos}
        setUpdatedPhotos={setUpdatedPhotos}
        openLightbox={() => setOpen(true)}
        setIndex={setIndex}
        car={car}
        dealership={dealership}
      />
      {updatedPhotos.length > 0 && (
        <div className={"btn btn-outline-primary" + (loading ? ' disabled' : '')} onClick={sendPhotosToServer} disabled={loading}>
          {loading ? "Loading..." : "Update"}
        </div>
      )}
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={photos.map((photo) => ({
          src: photo.image.url
        }))}
        index={index}
        on={{ view: ({ index: currentIndex }) => setIndex(currentIndex) }}
      />
    </div>
  )
}

export default Wrapper