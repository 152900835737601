import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import Loading from '../Loading'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Subtitle } from './CarSearchItem'
import Select from 'react-select'
import { humanize } from '../editor/common/Utils'
import moment from 'moment'
import CarScore from './CarScore'

const Car = (rowData) => {
  let car = rowData.car
  return (
    <div className="d-flex">
      <img
        src={car.primary_image_url}
        alt={car.name}
        height={50}
        className="rounded mr-3"
        style={{ objectFit: 'cover' }}
      />
      <div>
        <div>
          <Link to={`/cars/${car.slug}`}>{car.name}</Link>
        </div>
        <small className="text-secondary">
          <Subtitle car={car} />
        </small>
        <div className="small text-secondary">
          Leads: {car.leads_count} | Views: {car.views}
        </div>
      </div>
    </div>
  )
}

export const CarGrade = (rowData) => {

  let car = rowData.car

  if (!car.car_grade) {
    return (
      <small className="text-secondary">
        Not Graded
      </small>
    )
  }

  return (
    <CarScore hit={car} />
  )
}

const TimeData = ({ dateTime }) => {
  const now = moment();
  const endTime = moment(dateTime);

  const duration = moment.duration(endTime.diff(now));
  const days = duration.days();
  const hours = duration.hours();

  return (
    <>
      {moment(dateTime).format("DD/MM/YY HH:mm")}
      <div className="small text-secondary">
        {days}d and {hours}h
      </div>
    </>
  )
}

const StartedAt = (rowData) => {
  return (
    <TimeData dateTime={rowData.created_at} />
  )
}

const Reasons = (rowData) => {

  return (
    <ul>
      {rowData.reasons.map((reason, i) => (
        <li key={i}>{reason}</li>
      ))}
    </ul>
  )
}

const EndsAt = (rowData) => {
  if (!rowData.end_time) {
    return (
      <span>Nil end time</span>
    )
  }

  return (
    <TimeData dateTime={rowData.end_time} />
  )
}

let options = ['used', 'new', 'demo'].map(o => ({ value: o, label: humanize(o) }))
let statusOptions = ['in_stock', 'sold'].map(o => ({ value: o, label: humanize(o) }))

const SmartPublisher = () => {
  let [stats, setStats] = useState(false)
  let [stockRestrictions, setStockRestrictions] = useState(false)
  let [loading, setLoading] = useState(false)
  let [carType, setCarType] = useState(options[0])
  let [status, setStatus] = useState(statusOptions[0])

  let { dealershipSlug } = useParams()

  useEffect(() => {
    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/cars/stock_restrictions.json?car_type=${carType.value}&status=${status.value}`)
      .then(res => res.json())
      .then(res => {
        setStockRestrictions(res.stock_restrictions)
        setStats(res.stats)
        setLoading(false)
      })
  }, [carType, status])

  return (
    <div className="p-3 w-100">
      <div className="d-flex mb-2">
        <h4>Smart Publisher</h4>
        <div className="ml-auto d-flex">
          <Link
            to={`/dealerships/${dealershipSlug}/cars/smart_publisher_settings`}
            className="btn btn-outline-primary mr-2"
          >
            Settings
          </Link>
          <Select
            options={statusOptions}
            defaultValue={status}
            onChange={newValue => setStatus(newValue)}
            className="mr-2"
          />
          <Select
            options={options}
            defaultValue={carType}
            onChange={newValue => setCarType(newValue)}
          />
        </div>
      </div>

      <div className="box">
        <div className="p-3">
          <h5>{carType.label} Cars</h5>
          {stats && (
            <div>
              <ul>
                <li>Total {carType.label} cars: {stats.total_cars}</li>
                <li>Current Stock Restrictions: {stats.stock_restrictions_count}</li>
                <li>Percentage Restricted: {stats.percentage_restricted}%</li>
              </ul>
            </div>
          )}
          {loading && <Loading />}
        </div>
        <DataTable value={stockRestrictions} loading={loading}>
          <Column body={Car} header="Car" />
          <Column field="reasons" header="Reason" body={Reasons} />
          <Column sortable body={CarGrade} field="car_grade" header="Car Grade" />
          <Column field="created_at" body={StartedAt} header="Started" />
          <Column field="end_time" body={EndsAt} header="Ends" />
        </DataTable>
      </div>
    </div>
  )
}

export default SmartPublisher