import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { standardHeaders } from '../../entries/utils'
import { Toast } from 'primereact/toast'
import { Link, useParams } from 'react-router-dom'
import { handleNotificationEvent } from '../utils'
import { Dropdown } from 'primereact/dropdown'
import { linkFacebookAccount } from '../utils'
import * as Routes from '../../../routes'

type AdAccount = {
  id: number
  name: string
  dealershipId?: number
  dealershipName?: string
  dealershipSlug?: string
}

type Dealership = { id: number; name: string; city: string; city_slug: string }

async function fetchAdAccounts(dealershipSlug: string | undefined): Promise<AdAccount[]> {
  try {
    const route = dealershipSlug
      ? `/dealerships/${dealershipSlug}/social_ad_accounts.json`
      : Routes.ad_accounts_social_ad_accounts_path()

    const response = await fetch(route)
    const data = await response.json()
    return data?.adAccounts
  } catch (error) {
    console.error('Error:', error)
    return []
  }
}

async function updateDealershipOnSocialAdAccounts(
  adAccounts: AdAccount[],
  notification: React.MutableRefObject<Toast>
): Promise<void> {
  try {
    const URL = Routes.bulk_update_social_ad_accounts_path()
    const response = await fetch(URL, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({ ad_accounts: adAccounts }),
    })
    const data = await response.json()
    if (response.ok) handleNotificationEvent(notification, data.message, 'success')
    else handleNotificationEvent(notification, data.message, 'error')
  } catch (error) {
    console.error('Error:', error)
  }
}

const DealershipDropdown: React.FC<{
  adAccountId: number
  updateAdAccountDealership: (adAccountId: number, dealership: Dealership) => void
}> = ({ adAccountId, updateAdAccountDealership }) => {
  const [dealerships, setDealerships] = useState<Dealership[]>([])

  useEffect(() => {
    const fetchDealerships = async () => {
      try {
        const URL = `${Routes.dealership_ids_dealerships_path()}.json`
        const response = await fetch(URL)
        const data: Dealership[] = await response.json()
        // sort data by name property
        setDealerships(data.sort((a, b) => a.name.localeCompare(b.name)))
      } catch (error) {
        console.error('Error fetching dealerships:', error)
      }
    }

    fetchDealerships()
  }, [])

  const handleChange = (event: { value: Dealership }) => {
    updateAdAccountDealership(adAccountId, event.value)
  }

  return (
    <Dropdown
      placeholder="Set Dealership"
      onChange={handleChange}
      options={dealerships.map((dealership) => ({
        label: dealership.name,
        value: { id: dealership.id, name: dealership.name },
      }))}
    ></Dropdown>
  )
}

const Table: React.FC<{
  adAccounts: AdAccount[]
  updateAdAccountDealership: (adAccountId: number, dealership: Dealership) => void
  notification: MutableRefObject<Toast>
  isDealership: boolean
}> = ({ adAccounts, updateAdAccountDealership, notification, isDealership }) => (
  <>
    <div className="row">
      <div className="col-12">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Ad Acccount Name</th>
              <th>Dealership</th>
            </tr>
          </thead>
          <tbody>
            {adAccounts
              ? adAccounts.map(({ id, name, dealershipId, dealershipName, dealershipSlug }) => (
                  <tr key={id}>
                    <td>
                      {isDealership ? (
                        <Link to={`/dealerships/${dealershipSlug}/social_ad_accounts/${id}`}>
                          {name}
                        </Link>
                      ) : (
                        <Link to={`/social_ad_accounts/facebook/${id}`}>{name}</Link>
                      )}
                    </td>
                    <td>
                      {dealershipId === null ? (
                        <DealershipDropdown
                          adAccountId={id}
                          updateAdAccountDealership={updateAdAccountDealership}
                        />
                      ) : (
                        <div>
                          {dealershipName}
                          {/* Only show this X button if we're in admin view */}
                          {isDealership ? null : (
                            <div
                              className="btn fas fa-times-circle"
                              style={{ color: 'red' }}
                              onClick={() =>
                                updateAdAccountDealership(id, {
                                  id: null,
                                  name: null,
                                  city: '',
                                  city_slug: '',
                                })
                              }
                            ></div>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
    {/*  Only show the save button in admin view */}
    {isDealership ? null : (
      <div className="row">
        <div className="col-12">
          <button
            className="btn btn-primary float-right"
            onClick={() => updateDealershipOnSocialAdAccounts(adAccounts, notification)}
          >
            Save
          </button>
        </div>
      </div>
    )}
  </>
)

const FacebookAdAccounts: React.FC<{ isDealership: boolean }> = ({ isDealership = false }) => {
  const notification = useRef<Toast>(null)
  const { dealershipSlug } = useParams<{ dealershipSlug: string }>()
  const [adAccounts, setAdAccounts] = useState<AdAccount[]>([])

  const updateAdAccountDealership = (adAccountId: number, dealership: Dealership): void => {
    setAdAccounts((prevState) =>
      prevState.map((adAccount) =>
        adAccount.id === adAccountId
          ? { ...adAccount, dealershipId: dealership.id, dealershipName: dealership.name }
          : adAccount
      )
    )
  }

  // Fetch the ad accounts on initial page load
  useEffect(() => {
    const loadAdAccounts = async () => {
      const accounts = await fetchAdAccounts(dealershipSlug)
      setAdAccounts(accounts)
    }
    loadAdAccounts()
  }, [])

  return (
    <>
      <Toast ref={notification} />
      <div className="container mt-3">
        <div className="row">
          <div className="col-6">
            <h1>Facebook Ad Accounts</h1>
          </div>
          <div className="col-6 m-0">
            <div
              className="float-right btn btn-outline-primary btn-sm"
              onClick={() => linkFacebookAccount(dealershipSlug)}
            >
              <span>
                <i className="fab fa-facebook-f mr-2 "></i>Link Facebook Account
              </span>
            </div>
          </div>
        </div>
        <Table
          adAccounts={adAccounts}
          updateAdAccountDealership={updateAdAccountDealership}
          notification={notification}
          isDealership={isDealership}
        />
      </div>
    </>
  )
}

export default FacebookAdAccounts
