import { useState, useContext } from 'react';
import { CarContext } from '../contexts';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Modal from '../entries/modal'
import BeforeAfterPhoto from './beforeAfterPhoto'
import Loading from '../Loading'
import { standardHeaders } from '../entries/utils';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';

const PhotoInfo = ({ id }) => {
  let [visible, setVisible] = useState(false)
  let { car } = useContext(CarContext)
  let [photo, setPhoto] = useState(false)

  const showInfo = () => {
    fetchInfo()
    setVisible(true)
  }

  const fetchInfo = () => {
    fetch(`/cars/${car.slug}/photos/${id}.json`)
      .then(res => res.json())
      .then(res => {
        setPhoto(res)
      })
  }

  return (
    <>
      <a className="dropdown-item small" onClick={() => showInfo()}>
        <i className="fa fa-info mr-2" />
        Photo Info
      </a>
      <Dialog
        header="Photo Info"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <ul>
          <li>
            Photo created {moment(photo.created_at).format('DD/MM/YY')}
          </li>
          <li>
            <b>Source:</b> {photo.source}
          </li>
          {photo.user && photo.user?.name && (
            <li>
              <b>Photo Uploaded by:</b> {photo.user?.name}
            </li>
          )}
        </ul>
      </Dialog>
    </>
  )
}

const PhotoDropdown = ({ item, car_slug, setUpdatedPhotos, photos, dealership }) => {
  let [open, setOpen] = useState(false)
  let [loading, setLoading] = useState(false)
  let [updating, setUpdating] = useState(false)
  let [setting, setSetting] = useState('no_blur')
  let [url, setUrl] = useState({ blur: false, no_blur: false })
  let [originalUrl, setOriginalUrl] = useState(false)

  const removeBackground = ({ blur = false }) => {
    let newSetting = blur ? 'blur' : 'no_blur'
    setSetting(newSetting)
    setOpen(!open, $(`#photo-editor-${item.id}`).modal())
    if (url[newSetting]) {
      return
    }
    setLoading(true)
    fetch(`/cars/${car_slug}/photos/${item.id}/remove_background?blur=${blur}`, {
      method: 'GET',
      headers: standardHeaders
    }).then(response => response.json()).then(data => {
      setUrl({ ...url, [newSetting]: data.url })
      setOriginalUrl(data.original_url)
    })
  }

  const deletePhoto = () => {
    fetch(`/cars/${car_slug}/photos/${item.id}`, {
      method: 'DELETE',
      headers: standardHeaders
    }).then(response => response.json()).then(data => {
      setUpdatedPhotos(photos.filter(photo => photo.id !== item.id))
    })
  }

  const updateImage = ({ params }) => {
    setUpdating(true)
    fetch(`/cars/${car_slug}/photos/${item.id}`, {
      method: 'PATCH',
      body: JSON.stringify({ photo: { skip_filename_validation: true, ...params } }),
      headers: standardHeaders
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to update photo');
      }
    }).then(() => {
      window.location.reload();
    }).catch(error => {
      console.error('Error updating photo:', error);
    }).finally(() => {
      setUpdating(false);
    });
  }

  const updateImageVisibility = () => {
    updateImage({ params: { visible: !item.visible } })
  }

  let backgroundRemoverEnabled = dealership?.background_remover

  if (item.stock_image) {
    return (
      <div className="small text-secondary text-center">
        <div className="mb-1">Stock Image</div>
        <div className="btn btn-outline-primary dropdown-toggle btn-sm btn-block" data-toggle="dropdown" id="dropdownToggle">
          <i className="fa fa-ellipsis-h" />
        </div>
        <div className="dropdown-menu">
          <a className={"dropdown-item small" + (updating ? ' disabled' : '')} onClick={updateImageVisibility} disabled={updating}>
            <i className={"fa fa-eye" + (item.visible ? '-slash' : '') + " mr-2"} />
            {item.visible ? 'Hide Photo' : 'Show Photo'}
          </a>
        </div>
      </div>
    )
  }

  return (
    <div className="dropdown mt-1">
      <div className="btn btn-outline-primary dropdown-toggle btn-sm btn-block" data-toggle="dropdown">
        <i className="fa fa-ellipsis-h" />
      </div>
      <div className="dropdown-menu">
        <a className={"dropdown-item small" + (backgroundRemoverEnabled ? '' : ' disabled')} onClick={() => removeBackground({ blur: false })} disabled={!backgroundRemoverEnabled}>
          Remove Background <span className="badge badge-danger">BETA</span>
        </a>
        <a className={"dropdown-item small" + (backgroundRemoverEnabled ? '' : ' disabled')} onClick={() => removeBackground({ blur: true })} disabled={!backgroundRemoverEnabled}>
          Remove Background & Blur plate <span className="badge badge-danger">BETA</span>
        </a>
        <PhotoInfo id={item.id} />
        <a className="dropdown-item small" onClick={() => deletePhoto()}>
          <i className="fa fa-trash mr-2" />
          Delete Photo
        </a>
      </div>
      <Modal
        id={`photo-editor-${item.id}`}
        header={`Edit Photo`}
      >
        {loading && <Loading />}
        {url[setting] && <img
          src={url[setting]}
          style={{ display: 'none' }}
          onLoad={() => setLoading(false)}
        />}
        {!loading && url && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <BeforeAfterPhoto first_image={url[setting]} second_image={originalUrl} />
            <div>
              {item.effects.includes('no_background') ? (
                <div className={"btn btn-outline-primary mt-3" + (updating ? ' disabled' : '')} onClick={() => updateImage({ params: { effects: [] } })} disabled={updating}>
                  {updating ? 'Loading...' : 'Reset to original'}
                </div>
              ) : (
                <div className={"btn btn-outline-primary mt-3" + (updating ? ' disabled' : '')} onClick={() => updateImage({ params: { effects: ['no_background'] } })} disabled={updating}>
                  {updating ? 'Loading...' : 'Update'}
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export function SortableItem({ item, openLightbox, car_slug, setIndex, index, setUpdatedPhotos, photos, dealership }) {
  let attributes = {};
  let listeners = {};
  let setNodeRef = () => { };
  let style = {};

  // Only apply useSortable if the item is not a stock image
  if (!item.stock_image) {
    const sortable = useSortable({ id: item.id });
    attributes = sortable.attributes;
    listeners = sortable.listeners;
    setNodeRef = sortable.setNodeRef;
    style = {
      transform: CSS.Transform.toString(sortable.transform),
      transition: sortable.transition,
    };
  }

  return (
    <div className="col-6 col-md-2 mb-2">
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        {item.stock_image && (
          <div className={"badge badge-secondary badge-sm w-100 mb-1"}>
            {item.visible ? 'Visible' : 'Hidden'}
          </div>
        )}
        <img
          src={item.image.thumb.url}
          height={100}
          className='img-fluid car-thumb-photo rounded'
          style={{ minHeight: 100 }}
          onClick={() => { openLightbox(); setIndex(index) }}
          loading="lazy"
        />
        {item.labels && item.labels.length > 0 && (
          <div className="mt-1 badge badge-secondary badge-sm w-100">
            {item.labels[0]}
          </div>
        )}
        {car_slug && (
          <PhotoDropdown
            car_slug={car_slug}
            item={item}
            original_image={item.image.thumb.url}
            setUpdatedPhotos={setUpdatedPhotos}
            photos={photos}
            dealership={dealership}
          />
        )}
      </div>
    </div>
  );
}
