import { useRef, useState } from 'react'
import { FormProvider, useFieldArray, useForm, useFormContext, useFormState } from 'react-hook-form'

import { standardHeaders } from '../../entries/utils'
import { InputText } from 'primereact/inputtext'
import { PendingText } from '../../tanstackPlaceholderUtils'
import { Toast } from 'primereact/toast'

export type RedirectsFormData = {
  redirects: {
    source: string
    destination: string
    permanent?: boolean
  }[]
}

type EditableAction = 'all' | 'none' | number // edit all, editor turned off, or edit a specific row

/** Table containing all website redirects, which become editable based on the state of `editAction` */
const RedirectsFormFields = ({
  editAction,
  setEditAction,
}: {
  editAction: EditableAction
  setEditAction: (val: EditableAction) => void
}) => {
  const { control, register, watch } = useFormContext<RedirectsFormData>()
  const { errors } = useFormState()

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: 'redirects',
  })

  /** Up to date form redirects array */
  const redirects = watch('redirects')

  /**
   * Check that a redirect path is valid
   * https://nextjs.org/docs/pages/api-reference/next-config-js/redirects
   **/
  const validatePath = (path: string) => {
    if (!path) {
      return 'A path is required'
    }
    // This regex tests for full path urls
    if (/^https:\/\/[A-Za-z0-9.-]+\.[A-Za-z]{2,}\/?.*$/.test(path)) {
      // offsite url redirects are valid
      return true
    }
    if (
      path !== '/' &&
      /**
       * This regex tests for local path urls and Next.js wildcards paths.
       * Examples of valid paths include "/hello/world" and "/cars/slug+".
       * Examples of invalid local paths include "hello/world" and "/hello/world/".
       * */
      !/^\/[a-zA-Z0-9-]+(\/:[a-zA-Z0-9-]+(\*|\+)?)?(\/[a-zA-Z0-9-]+)*$/.test(path)
    ) {
      return "Invalid path. Must start with '/' and not end with '/'"
    }
    return true
  }

  /** Check a path is valid and is not included multiple times */
  const validatePathAndUnique = (path: string) => {
    const initialCheck = validatePath(path)
    if (typeof initialCheck === 'string') {
      return initialCheck
    }
    const fieldsWithSource = redirects.filter((f) => f.source === path)
    if (fieldsWithSource.length > 1) {
      return 'Redirect source must be unique'
    }
    return true
  }

  return (
    <div className="mb-3">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th style={{ width: '38%' }} scope="col">
              Source
            </th>
            <th style={{ width: '38%' }} scope="col">
              Destination
            </th>
            <th style={{ width: '24%' }} scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => {
            const isEditable = editAction === 'all' || editAction === index
            return (
              <tr key={item.id}>
                <td>
                  <InputText
                    className={'w-100 p-1' + (isEditable ? '' : ' border-0 bg-white')}
                    {...register(`redirects.${index}.source`, {
                      validate: validatePathAndUnique,
                    })}
                    placeholder="Source"
                    disabled={!isEditable}
                  />

                  {errors?.redirects?.[index]?.source && (
                    <span className="d-block small text-danger">
                      {errors.redirects[index]?.source?.message}
                    </span>
                  )}
                </td>
                <td>
                  <InputText
                    className={'w-100 p-1 ' + (isEditable ? '' : ' border-0 bg-white')}
                    {...register(`redirects.${index}.destination`, {
                      validate: validatePath,
                    })}
                    placeholder="Destination"
                    disabled={!isEditable}
                  />
                  {errors?.redirects?.[index]?.destination && (
                    <span className="d-block small text-danger">
                      {errors.redirects[index]?.destination?.message}
                    </span>
                  )}
                </td>
                <td className="d-flex justify-content-end">
                  {isEditable ? (
                    <>
                      <button
                        style={{ flex: 0.333 }}
                        type="submit"
                        className="btn btn-sm btn-outline-success mr-1"
                      >
                        <PendingText text="Save" />
                      </button>
                    </>
                  ) : (
                    <button
                      style={{ flex: 0.333 }}
                      type="button"
                      className="btn btn-sm btn-outline-success mr-1"
                      onClick={() => setEditAction(index)}
                    >
                      Edit
                    </button>
                  )}
                  <button
                    style={{ flex: 0.333 }}
                    type="button"
                    className="btn btn-sm btn-outline-primary mr-1"
                    onClick={() => {
                      insert(index + 1, {
                        source: redirects[index].source,
                        destination: redirects[index].destination,
                      })
                      setEditAction(index + 1)
                    }}
                  >
                    Copy
                  </button>
                  <button
                    style={{ flex: 0.333 }}
                    type="button"
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => remove(index)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-outline-primary mr-3"
          onClick={() => {
            append({ source: '/example', destination: '/' })
            setEditAction(fields.length)
          }}
        >
          Add New Redirect
        </button>
        {fields.length !== 0 && (
          <>
            {editAction ? (
              <button id="save" disabled={fields.length === 0} className="btn btn-primary mr-3">
                <PendingText />
              </button>
            ) : (
              <button
                type="button"
                id="edit"
                onClick={(e) => {
                  e.preventDefault()
                  setEditAction('all')
                }}
                className="btn btn-primary mr-3"
              >
                Edit Redirects
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export const WebsiteRedirectsForm = ({
  websiteSlug,
  defaultValues = {},
}: {
  websiteSlug: string
  defaultValues?: Partial<RedirectsFormData>
}) => {
  // Manage form state
  const [editAction, setEditAction] = useState<EditableAction>('none')
  const form = useForm<RedirectsFormData>({
    mode: 'onSubmit',
    defaultValues,
  })
  const toast = useRef(null)

  // Handle form data submission
  const onSubmit = async (data: RedirectsFormData) => {
    // We can enhance this with error handling using:
    // https://tanstack.com/query/latest/docs/framework/react/guides/mutations
    const mutation = await fetch(`/websites/${websiteSlug}`, {
      method: 'PATCH',
      body: JSON.stringify({
        website: {
          redirects: data,
        },
      }),
      headers: standardHeaders,
    })

    if (!mutation.ok) {
      toast.current.show({
        severity: 'error',
        summary: 'Unable To Save',
        detail: `A server error occurred when saving the data: ${mutation.statusText}`,
      })
    } else {
      setEditAction('none')
      toast.current.show({
        severity: 'success',
        summary: 'Saved Successfully',
      })
    }
  }
  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          await onSubmit(data)
        })}
      >
        <Toast ref={toast} />
        <div className="bg-white table-responsive">
          <RedirectsFormFields editAction={editAction} setEditAction={setEditAction} />
        </div>
      </form>
    </FormProvider>
  )
}
